 <template>

    <el-container class="container">
        <el-header>
            <el-row type="flex" class="row-bg" justify="space-between">
                <el-col :span="6" style="margin-left:20px;line-height:  60px;font-size: 28px;letter-spacing:10px;color: white;">
                    我的走访日记
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple-light"></div>
                </el-col>
                <el-col :span="2">
                    <div class="grid-content bg-purple" style="line-height:  60px;color: white;" @click="logout"><i class="el-icon-s-promotion"></i>登出系统</div>
                </el-col>
            </el-row>
        </el-header>

        <el-container>
            <el-aside width="220px">
                <!-- 左侧导航 -->
                <!-- 默认加载页路由路径 -->
                <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" :router="true">
                    <!-- :router="true"开启导航 -->
                    <el-menu-item v-for="(item,index) in routerlist" :index="item.url">
                        <i :class="item.icon"></i>
                        <span slot="title">{{ item.title }} </span>
                    </el-menu-item>
                </el-menu>
                <!-- 左侧导航 -->
            </el-aside>
            <el-main style="background-color: #fff;padding: 0;">
                <router-view style="margin-bottom: 80px !important;" /><!-- 路由出口 -->
                <div class="login-footer">
                    <!-- <span>&copy; 2023 版权所有</span>&nbsp; -->
                    <span> <a href="https://beian.miit.gov.cn/" target="_blank">陇ICP备2023001093号</a></span>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import { getToken, removeToken } from '@/apps/utils/auth'
export default {
    data () {
        return {
            activeIndex: "/system/index",
            routerlist: [
                {
                    title: " 主 页 ",
                    url: "/system/index",
                    icon: "el-icon-s-home"
                },
                {
                    title: " 巡查单 ",
                    url: "/system/xcd",
                    icon: "el-icon-s-order"
                },
                {
                    title: " 商 户 ",
                    url: "/system/business",
                    icon: "el-icon-s-shop"
                },
                {
                    title: " 商 品 ",
                    url: "/system/goods",
                    icon: "el-icon-s-goods"
                },
                {
                    title: " 等 级 ",
                    url: "/system/level",
                    icon: "el-icon-s-flag"
                },
                {
                    title: " 用 户 ",
                    url: "/system/user",
                    icon: "el-icon-s-custom"
                },

                //
            ]
        }
    },
    methods: {

        logout () {
            removeToken()
            sessionStorage.clear()
            this.$router.push({ path: this.redirect || '/login' })
        }
    },
    mounted () {
        this.activeIndex = this.$route.path
    },
}
</script>

<style scoped>
a:link {
    text-decoration: none; /* 指正常的未被访问过的链接*/
}
a:visited {
    text-decoration: none; /*指已经访问过的链接*/
}
a:hover {
    text-decoration: none; /*指鼠标在链接*/
}
a:active {
    text-decoration: none; /* 指正在点的链接*/
}
a {
    color: #999;
}
.login-footer {
    width: 100%;
    background-color: #fff;
    /* height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%; */
    /* text-align: center;
    color: #999;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px; */
}
/* 去掉选项右侧边框 */
.el-menu {
    border-right: none;
    background-color: #fff !important;
}
/* 设置整体布局高度 */
.container {
    height: 100vh;
}

.el-header {
    background-image: url(../public/assets/bg_1.png);
}

.el-aside {
    background-color: #fff;
    color: #303133;
    text-align: left;
    line-height: 300px;
    border-right: 1px solid #ececec;
}

.el-menu-item {
    background-color: #fff;
    font-size: 16px;
    padding: none !important;
}
.el-menu-item i {
    font-size: 24px;
}

.el-menu-item.is-active {
    background-color: rgba(24, 144, 255, 0.1);
    border-left: 4px solid #005edd !important;
}
body > .el-container {
    margin-bottom: 40px;
}
</style>
