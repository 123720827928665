<template>
    <div v-loading="loading">
        <el-col :span="24">
            <el-form-item :label="label">
                <el-upload class="upload-demo" action="" :http-request="upload" :show-file-list="false">
                    <el-button size="mini" type="primary">点击上传</el-button>
                </el-upload>
            </el-form-item>
        </el-col>
        <el-col :span="24" style="margin-bottom: 15px;" v-if="fileslist.length>0">
            <el-form-item label="附件列表">
                <div v-for="item in fileslist" style="height: 30px;line-height: 30px;">
                    <el-col :span="18">{{item.name }}</el-col>
                    <el-col :span="6">
                        <el-button-group>
                            <el-button size="mini" type="primary" icon="el-icon-search" @click="preview(item.id)">预览</el-button>
                            <el-button type="danger" size="mini" icon="el-icon-delete" @click="delimg(item.id)">删除</el-button>
                        </el-button-group>
                    </el-col>
                </div>
            </el-form-item>
        </el-col>
        <preview ref="preview"></preview>
    </div>
</template>
<script>
import { uploadfile, filelist, filedel } from '@/apps/api/uplaod'
import preview from '@/apps/components/File/preview.vue'
export default {
    name: 'upload',
    components: {
        preview
    },
    props: {
        domain: {
            type: String,
            default: {}
        },
        business: {
            type: String,
            default: {}
        },
        label: {
            type: String,
            default: {}
        }
    },
    watch: {
        'business': function (val) {
            if (val) {
                this.filelists()
            }
        },
        'domain': function (val) {
            if (val) {
                this.filelists()
            }
        }

    },
    data () {
        return {
            loading: false,
            fileslist: [],
        }
    },
    mounted () {
        this.filelists()
    },
    // updated () {
    //     this.filelists()
    // },
    methods: {
        // 预览

        preview (id,) {
            this.$refs.preview.business = this.business;
            this.$refs.preview.domain = this.domain;
            this.$refs.preview.previewOpen = true
            this.$refs.preview.preview(id);
        },
        //删除
        delimg (value) {
            this.loading = true
            let data = {
                id: value,
                status: 0
            }
            filedel(data).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.filelists()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'danger'
                    })
                    this.loading = false
                }


            })
        },
        // 上传
        upload (param) {
            this.loading = false
            const formData = new FormData()
            formData.append('uploadfile', param.file)
            formData.append('domain', this.domain)
            formData.append('business', this.business)
            formData.append('objectName', param.file.name)
            uploadfile(formData).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.filelists()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'danger'
                    })
                    this.loading = false
                }
            })
        },
        // 附件列表
        filelists () {
            this.loading = true

            let data = {
                business: this.business,
                domain: this.domain,
                status: 1
            }
            filelist(data).then(res => {

                if (res.code == 200) {
                    this.fileslist = res.data
                    console.log(this.fileslist)
                    this.loading = false
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'danger'
                    })
                    this.loading = false

                }

            })
        },
    },
}
</script>