<template>
    <div>
        <div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>等级列表</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="tablelist">

                <el-form :model="form" ref="planQueryForm" :inline="true">
                    <el-row :gutter="10">

                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item label="等级名">
                                <el-input v-model="form.type" style="width: 300px;" placeholder="请输入等级名" clearable size="small" type="text" />
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item label="状态">
                                <el-select v-model="form.status" placeholder="请选择">
                                    <el-option label="启用" value="1"> </el-option>
                                    <el-option label="禁用" value="0"> </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item>
                                <el-button type="success" icon="el-icon-search" size="mini" @click="getlevellist">搜索</el-button>
                                <el-button type="warning" icon="el-icon-refresh-right" size="mini" @click="resetsearch">重置</el-button>
                                <el-button type="primary" icon="el-icon-plus" size="mini" @click="addlevel">新增</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table :data="levellist" style="width: 100%" v-loading="loading" :row-class-name="tableRowClassName">
                    <el-table-column label="序号" align="center" type="index" width="60" fixed="left"></el-table-column>
                    <el-table-column prop="type" label="等级" align="center"></el-table-column>
                    <el-table-column prop="shopNumber" label="所需数量" align="center"></el-table-column>
                    <el-table-column prop="shopVariety" label="所需种类" align="center"></el-table-column>
                    <el-table-column prop="lastUpdateName" label="最后一次修改人" align="center" width="120"></el-table-column>
                    <el-table-column prop="lastUpdateDate" label="最后一次修改时间" align="center" width="140"></el-table-column>
                    <el-table-column prop="status" label="状态" align="center" :formatter="levelstatusFormatter"> </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center" width="220">
                        <template slot-scope="scope">
                            <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                            <el-button @click="updateStatus(scope.row.id,0)" size="small" v-if="scope.row.status==1" type="text" class="dangertext">禁用</el-button>
                            <el-button @click="updateStatus(scope.row.id,1)" size="small" v-if="scope.row.status==0" type="text" class="succestext">启用</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination v-show="total > 0" @size-change="getlevellist" @current-change="getlevellist" :current-page="form.pageNum" :page-sizes="[10, 15, 20]" :page-size="form.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <edit ref="edit"></edit>
    </div>
</template>
<script>
import { levelList, levelUpdate } from '@/apps/api/level'
import edit from '@/apps/views/level/edit'
export default {
    name: 'levellist',
    components: {
        edit
    },
    data () {
        return {
            loading: false,
            form: {
                type: '',
                status: '',
                pageSize: 10,
                pageNum: 1
            },
            total: 0,
            levellist: [],
            areatreelist: null
        }
    },
    created () {
        this.getlevellist()
    },
    methods: {
        tableRowClassName ({ row, rowIndex }) {
            if (row.status == 0) {
                return 'danger-row';
            }
            return '';
        },
        resetsearch () {
            this.form = {
                status: '',
                type: '',
                pageSize: 10,
                pageNum: 1
            }
            this.getlevellist()
        },
        handleChange (value) {
            this.form.areacode = value[value.length - 1]
        },
        // 获取用户
        getlevellist () {
            this.loading = true
            levelList(this.form).then(res => {
                if (res.code == 200) {
                    this.levellist = res.data
                    this.total = res.total
                    this.loading = false
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                    this.loading = false
                }
            })
        },
        // 状态修改
        updateStatus (id, status) {
            this.loading = true
            let data = {
                id: id,
                status: status,
            }
            levelUpdate(data).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                }
                this.getlevellist()
            })
        },
        levelstatusFormatter (row, column) {
            switch (row.status) {
                case '0':
                    return "禁用"
                case '1':
                    return "启用"

            }
        },
        // 打开修改用户页面
        edit (value) {
            setTimeout(() => {
                this.$refs.edit.handleupdate(value)
            }, 1000)
        },
        addlevel () {
            setTimeout(() => {
                this.$refs.edit.handleadd()
            }, 1000)
        }

    }
}
</script>
<style>
</style>
