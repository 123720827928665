import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './apps/components/style/index.css'
import '@/apps/public/assets/style.css'
import App from './App.vue'
import router from './router'
import store from './store'


Vue.use(VueClipboard)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')