/* eslint-disable space-before-function-paren */
import request from '@/apps/utils/request'

// 地区列表
export function areaList(data) {
    return request({
        url: 'mn/area/list',
        method: 'POST',
        data: data
    })
}


// 地区树
export function areaTree(data) {
    return request({
        url: 'mn/area/treelist',
        method: 'POST',
        data: data
    })
}


// 返回完整地区名
export function areaName(data) {
    return request({
        url: 'mn/area/getAreaName',
        method: 'POST',
        data: data
    })
}