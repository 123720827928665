<template>
    <div id="container"></div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
    data () {
        return {
            //此处不声明 map 对象，可以直接使用 this.map赋值或者采用非响应式的普通对象来存储。
            map: null,
            markers: [],
            lnglat: [],
            // type: null,
        }
    },
    props: {
        type: {
            type: Number,
            default: {}
        }
    },
    mounted () {
        //DOM初始化完成进行地图初始化
        this.initMap();
    },

    methods: {

        initMap () {
            window._AMapSecurityConfig = {
                securityJsCode: 'e166bfe1cf783f524727880cd81ebd0f' // '「申请的安全密钥」',
            }
            AMapLoader.load({
                key: "2ced96ebd6582acae7284ba380721d4b",             // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ['AMap.Scale', 'AMap.ControlBar', 'AMap.Geolocation', 'AMap.Driving', "AMap.AutoComplete", "AMap.PlaceSearch", "AMap.DragRoute", "AMap.MouseTool", 'AMap.MapType'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap) => {
                this.map = new AMap.Map("container", {  //设置地图容器id
                    viewMode: "2D",    //是否为3D地图模式
                    zoom: 15,           //初始化地图级别
                    center: [103.744956, 36.072735], //初始化地图中心点位置
                });

                // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
                this.map.addControl(new AMap.Geolocation());

                // 在图面添加类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
                this.map.addControl(new AMap.MapType());

                // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
                this.map.addControl(new AMap.Scale());
                // 监听鼠标点击事件
                if (this.type == 1) {
                    this.map.on("click", this.clickMapHandler);
                }

            }).catch(e => {
            })
        },
        clickMapHandler (e) {
            this.lnglat = [e.lnglat.getLng(), e.lnglat.getLat()];
            this.setMarker(this.lnglat);
        },
        //  添加标记
        setMarker (lnglat) {
            this.removeMarker()
            let marker = new AMap.Marker({
                position: lnglat,
            });
            marker.setMap(this.map);
            this.markers.push(marker); // 在data中记录标记点
        },
        // ti
        moresetMarket (data) {
            let a = data
            for (let i = 0; i < data.length; i++) {
                let marker = new AMap.Marker({
                    position: data[i].market.split(','),
                });
                marker.setLabel({
                    offset: new AMap.Pixel(5, 5),  //设置文本标注偏移量
                    content: data[i].name + '[' + data[i].address + ']', //设置文本标注内容
                    direction: 'right' //设置文本标注方位
                });
                marker.setMap(this.map);
                this.markers.push(marker);
            }

        },
        // 删除之前后的标记点
        removeMarker () {
            // 判断是否存被标记的点，有--->移除
            if (this.markers) {
                this.map.remove(this.markers);
            }
        },
    },
}
</script>
<style scoped>
#container {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 600px;
}
</style>