<template>
    <!-- <div> -->
    <!-- 附件预览组件 -->
    <el-drawer :visible.sync="previewOpen" append-to-body title="文件预览" size="80%">
        <el-row>
            <el-col :span="8">
                <el-scrollbar style="height: 100%">
                    <ul class="my_fav_list">
                        <li v-for="(o, i) in fileList" :key="i" class="my_fav_list_li">
                            <div class="my_fav_list_a" :title="o.name" @click="preview(o.id)" style="background:#F0F8FF">
                                <div>
                                    <span><i class="el-icon-document" /> {{ o.name }}<br /></span>
                                    <span>上传时间:{{ o.lastUpdateDate }}</span>
                                </div>
                            </div>
                            <!-- <div class="previewDiv">
                                <img :src="previewSrc + o.attachPath" alt="">
                            </div> -->
                            <label class="my_fav_list_label">
                                <el-button-group>
                                    <el-tooltip class="item" effect="dark" content="点击下载" placement="top-start">
                                        <!-- <el-button size="mini" type="primary" icon="el-icon-download" @click="download(o.id)" /> -->
                                    </el-tooltip>
                                </el-button-group>
                            </label>
                        </li>
                    </ul>
                </el-scrollbar>
            </el-col>
            <el-col :span="16">
                <div :style="{width: '100%',height: dialogHeight + 'px','margin-right': '5px',}">
                    <section ref="print" class="recordImg" id="printRecord">
                        <img :src="fileSrc" />
                    </section>
                </div>
            </el-col>
        </el-row>
    </el-drawer>
</template>
<script>
import axios from "axios";
import { filelist, downloadfile } from '@/apps/api/uplaod'
const baseUrl = process.env.VUE_APP_SERVICE_URL
export default {
    data () {
        return {
            fileSrc: "",
            // 文件预览模态框高度
            dialogHeight: 300,
            dialogWidth: 500,
            previewOpen: false,
            fileList: [],
        }
    },
    methods: {
        preview (id) {
            let data = {
                business: this.business,
                domain: this.domain,
                status: 1
            }
            filelist(data).then(res => {
                if (res.code == 200) {
                    this.fileList = res.data
                }
            })
            this.getfile(id)
        },
        getfile (id) {
            const blobData = {};
            downloadfile(id).then((res) => {
                this.fileSrc = window.URL.createObjectURL(
                    new Blob([res], blobData)
                );
            })

        }
    },
}
</script>