/* eslint-disable space-before-function-paren */
import request from '@/apps/utils/request'
// 新增安装包版本记录表

export function toLogin(data) {
    return request({
        url: '/mn/user/login',
        method: 'POST',
        data: data
    })
}