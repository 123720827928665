/* eslint-disable space-before-function-paren */
import requestfile from '@/apps/utils/requestfile'
import request from '@/apps/utils/request'


// 商家查询
export function uploadfile(data) {
    return requestfile({
        url: 'mn/attach/uploadfile',
        method: 'POST',
        data: data
    })
}


export function filelist(data) {
    return request({
        url: 'mn/attach/list',
        method: 'POST',
        data: data
    })
}

export function filelistno(data) {
    return request({
        url: 'mn/attach/listno',
        method: 'POST',
        data: data
    })
}


export function downloadfile(id) {
    return request({
        url: 'downloadFile?id=' + id,
        responseType: "blob",
        method: 'GET',
    })
}

export function filedel(data) {
    return request({
        url: 'mn/attach/del',
        method: 'POST',
        data: data
    })
}