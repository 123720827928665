/* eslint-disable space-before-function-paren */
import request from '@/apps/utils/request'

// 商家查询
export function levelList(data) {
    return request({
        url: 'mn/levelinfo/list',
        method: 'POST',
        data: data
    })
}

// 商家修改
export function levelUpdate(data) {
    return request({
        url: 'mn/levelinfo/update',
        method: 'POST',
        data: data
    })
}

// 商家修改
export function levelAdd(data) {
    return request({
        url: 'mn/levelinfo/add',
        method: 'POST',
        data: data
    })
}