<template>
    <div>
        <el-dialog ref="editDiaLog" :visible.sync="open" :close-on-click-modal="false" :close-on-press-escape="false" :width="dialogWidth" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="auto">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="商品名" prop="name">
                            <el-input v-model="form.name" placeholder="请输入商品名" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="价格" prop="price">
                            <el-input v-model="form.price" placeholder="请输入价格">
                                <template slot="append">元（¥）</template>
                            </el-input>

                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="商品评级介绍" prop="describe">
                            <el-input v-model="form.describe" placeholder="请输入商品评级介绍" />
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="状态" prop="isShow">
                            <el-radio-group v-model="form.isShow" size="small">
                                <el-radio-button label="3">全部评级</el-radio-button>
                                <el-radio-button label="2">数量评级</el-radio-button>
                                <el-radio-button label="1">价格评级</el-radio-button>
                                <el-radio-button label="0">禁用</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">提 交</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { areaList, areaTree } from '@/apps/utils/area'
import { goodsUpdate, goodsAdd } from '@/apps/api/goods'
import { getUUID } from "@/apps/utils/uuid"
export default {
    name: 'goodsedit',
    data () {
        return {
            isEdit: false,
            imageUrl: null,
            dialogWidth: '800px',
            open: false,
            form: {},
            areatreelist: null,
            rules: {
                name: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                price: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],

                describe: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                isShow: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ]
            }
        }
    },
    created () {
        let data = {
            areacode: 620100000000
        }
        areaTree(data).then(res => {
            this.areatreelist = res.data
        })
    },
    methods: {
        handleadd (value) {
            this.open = true
            this.form = {}
            this.form.id = getUUID()
            this.isEdit = false
        },
        handleChange (value) {
            this.form.areacode = value[value.length - 1]
        },
        handleupdate (value) {
            this.open = true
            this.form = {}
            this.form = value
            this.isEdit = true
        },
        reset () {
            this.form = {
                parkName: null,
                phone: null,
                introduction: null,
                parkPicture: null
            }
        },
        getParentList () {
            this.$parent.getgoodslist()
        },
        // 取消按钮
        cancel () {
            this.open = false
            this.reset()
            this.getParentList()
        },
        submitForm () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.isEdit) {
                        goodsUpdate(this.form).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    message: '修改成功',
                                    type: 'success'
                                })
                                this.getParentList()
                                this.open = false
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        })
                    } else {
                        goodsAdd(this.form).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    message: '添加成功',
                                    type: 'success'
                                })
                                this.getParentList()
                                this.open = false
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        })
                    }
                }
            })
        }
    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
