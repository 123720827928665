/* eslint-disable space-before-function-paren */
import request from '@/apps/utils/request'

// 爆品商品查询
export function bpgoodsList(data) {
    return request({
        url: 'mn/bpgoods/list',
        method: 'POST',
        data: data
    })
}

// 爆品商品修改
export function bpgoodsUpdate(data) {
    return request({
        url: 'mn/bpgoods/update',
        method: 'POST',
        data: data
    })
}

// 爆品商品修改
export function bpgoodsAdd(data) {
    return request({
        url: 'mn/bpgoods/add',
        method: 'POST',
        data: data
    })
}

// 爆品商品查询
export function delbpgoods(data) {
    return request({
        url: 'mn/bpgoods/del',
        method: 'DELETE',
        data: data
    })
}