<!-- eslint-disable comma-dangle -->
<template>
    <div class="login">
        <div class="login-header">
            <h3 class="title">我的走访日记</h3>
        </div>
        <!--登录框-->
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
            <h3 class="wellcome">欢迎回来</h3>
            <el-form-item prop="phoneNumber">
                <el-input v-model="loginForm.phoneNumber" type="text" auto-complete="off" placeholder="手机号">
                    <svg-icon slot="prefix" icon-class="user" class="el-icon-user" />
                </el-input>
            </el-form-item>
            <el-form-item prop="passWord">
                <el-input v-model="loginForm.passWord" type="passWord" auto-complete="off" placeholder="密码" @keyup.enter.native="handleLogin">
                    <svg-icon slot="prefix" icon-class="passWord" class="el-icon-lock" />
                </el-input>
            </el-form-item>
            <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0 25px 0;">记住密码</el-checkbox>
            <el-form-item style="width:100%;">
                <el-button :loading="loading" size="medium" type="primary" style="width:100%;height: 40px;font-size:16px;" @click.native.prevent="handleLogin">
                    <span v-if="!loading">登 录</span>
                    <span v-else>登 录 中...</span>
                </el-button>
            </el-form-item>
        </el-form>
        <!--底部-->
        <div class="login-footer">
            <!-- <span>&copy; 2023 版权所有</span>&nbsp; -->
            <span> <a href="https://beian.miit.gov.cn/" target="_blank">陇ICP备2023001093号</a></span>
        </div>
    </div>
</template>

<script>

import Cookies from 'js-cookie'
import { setToken } from '@/apps/utils/auth'
import { toLogin } from '@/apps/api/login'
export default {
    name: 'Login',
    data () {
        return {
            cookiepassWord: '',
            loginForm: {
                phoneNumber: '',
                passWord: '',
                rememberMe: false
            },
            loginRules: {
                phoneNumber: [
                    { required: true, trigger: 'blur', message: '手机号不能为空' }
                ],
                passWord: [
                    { required: true, trigger: 'blur', message: '密码不能为空' }
                ]
            },
            loading: false
        }
    },
    created () {
        // 如果在iframe中出现登录页，则登录已经失效，主窗口跳转登录页
        this.getCookie()
    },

    methods: {
        getCookie () {
            const phoneNumber = Cookies.get('phoneNumber')
            const passWord = Cookies.get('passWord')
            const rememberMe = Cookies.get('rememberMe')
            this.loginForm = {
                phoneNumber: phoneNumber === undefined ? this.loginForm.phoneNumber : phoneNumber,
                passWord: passWord === undefined ? this.loginForm.passWord : passWord,
                rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
            }
        },
        handleLogin () {
            this.loading = true
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    toLogin(this.loginForm).then(res => {
                        if (res.token) {
                            this.$message({
                                message: res.msg,
                                type: 'success',
                                duration: 2000
                            })
                            Cookies.set('phoneNumber', this.loginForm.phoneNumber)
                            Cookies.set('passWord', this.loginForm.passWord)
                            Cookies.set('rememberMe', this.loginForm.rememberMe)
                            let data = {
                                id: res.data.id,
                                name: res.data.name
                            }
                            sessionStorage.setItem('user', JSON.stringify(data))
                            setToken(res.token)
                            this.loading = false
                            this.$router.push({ path: this.redirect || '/' })
                        } else if (res.code == 200) {
                            this.loading = false

                            this.$message({
                                message: "账号密码不正确或账号处于异常状态",
                                type: 'danger',
                                duration: 2000
                            })
                        }


                    })
                }
            })
        }
    }
}
</script>

<style rel="stylesheet/scss" lang="css" scoped>
body {
    padding: none;
    margin: none;
    overflow: hidden;
}
a:link {
    text-decoration: none; /* 指正常的未被访问过的链接*/
}
a:visited {
    text-decoration: none; /*指已经访问过的链接*/
}
a:hover {
    text-decoration: none; /*指鼠标在链接*/
}
a:active {
    text-decoration: none; /* 指正在点的链接*/
}
a {
    color: white;
}

.login {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../public/assets/bg_2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
/* .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 1064px;
    background: url("../public/assets/bg_2.png") center center fixed no-repeat;
    background-size: cover;
} */
.login-header {
    height: 60px;
    line-height: 10px;
    position: fixed;
    top: 0;
    width: 100%;
    color: #fff;
    margin-left: calc(50% - 500px);
}
.login-header .title {
    text-align: left;
    color: #fff;
    font-family: Arial;
    letter-spacing: 1px;
    line-height: 150px;
    font-size: 36px;
    font-weight: 500;
}
.login-form {
    margin-top: 350px;
    border-radius: 6px;
    background: #fff;
    width: 400px;
    padding: 25px 25px 5px 25px;
    margin-left: 60%;
}
.login-form .wellcome {
    text-align: left;
    color: #333;
    font-size: 32px;
    margin-top: 20px;
    font-weight: 500;
}
.login-form .el-input {
    height: 38px;
}
.login-form .el-input input {
    height: 38px;
}
.login-form .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
}
.login-form .login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
}
.login-code {
    width: 33%;
    height: 38px;
    float: right;
}
.login-code img {
    cursor: pointer;
    vertical-align: middle;
}
.login-code-img {
    height: 38px;
}

.login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
}

@media (max-width: 1024px) {
    .login-form {
        width: 50%;
        min-width: 360px;
        margin: 0;
        position: unset;
    }

    .login-header .title {
        font-size: 24px;
        text-align: center;
    }

    .login-header,
    .login-footer {
        position: unset;
        width: 100%;
        margin: 0;
    }

    .login {
        flex-direction: column;
    }
}
</style>
