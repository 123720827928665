<template>
    <div>
        <div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>巡查单列表</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="tablelist">
                <el-form :model="form" ref="planQueryForm" :inline="true">
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                            <el-form-item label="商家名">
                                <el-select v-model="form.businessname" filterable remote placeholder="请输入商家名" :remote-method="remoteMethod" :loading="reloading" @paste.native.capture.prevent="handleFalse">
                                    <el-option v-for="item in businessoptions" :key="item.value" :label="item.label" :value="item.value" @click.native="businessclick(item)">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                            <el-form-item label="填写人">
                                <el-input v-model="form.instername" placeholder="请输入填写人" clearable size="small" type="text" />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                            <el-form-item label="填写日期">
                                <el-date-picker v-model="form.insterdate" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" :picker-options="pickerdisabledold">
                                </el-date-picker>
                                <!-- <el-input v-model="form.insterdate" placeholder="请输入填写日期" clearable size="small" type="text" /> -->
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
                            <el-form-item>
                                <el-button type="success" icon="el-icon-search" size="mini" @click="pageNum=1,getxcdlist()">搜索</el-button>
                                <el-button type="warning" icon="el-icon-refresh-right" size="mini" @click="resetsearch">重置</el-button>
                                <el-button type="primary" icon="el-icon-plus" size="mini" @click="addxcd">新增</el-button>
                                <el-button type="primary" icon="el-icon-files" size="mini" @click="exportVisible=true,exportform={}">导出</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table :data="xcdlist" style="width: 100%" v-loading="loading" :row-class-name="tableRowClassName">
                    <el-table-column label="序号" align="center" type="index" width="60" fixed="left"></el-table-column>
                    <el-table-column prop="businessname" label="商家名" align="center"></el-table-column>
                    <el-table-column prop="businessaddress" label="商家地址" align="center"></el-table-column>
                    <el-table-column prop="instername" label="填写人" align="center"></el-table-column>
                    <el-table-column prop="insterdate" label="填写日期" align="center"></el-table-column>
                    <el-table-column prop="iscupboardc" label="达标" align="center">
                        <template slot-scope="scope">
                            {{ iscupboardcFormatter(scope.row.iscupboardc) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="lastupdatename" label="最后一次修改人" align="center" width="120"></el-table-column>
                    <el-table-column prop="lastupdatedate" label="最后一次修改时间" align="center" width="160"></el-table-column>
                    <el-table-column fixed="right" label="操作" align="center" width="220">
                        <template slot-scope="scope">
                            <el-button @click="copy(scope.row.id)" type="text" size="small">分享</el-button>

                            <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination v-show="total > 0" @size-change="sizechange" @current-change="currentchange" :current-page="form.pageNum" :page-sizes="[10, 15, 20]" :page-size="form.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <edit ref="edit"></edit>
        <el-dialog width="1000px" title="文件导出" :visible.sync="exportVisible" append-to-body>
            <!-- <MapContainer ref="MapContainer" :type="1"></MapContainer> -->
            <el-form ref="form" :model="exportform" :rules="exportrules" label-width="auto" style="margin: 10px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="导出类型" prop="type">
                            <el-select v-model="exportform.type" placeholder="请选择导出类型">
                                <el-option label="EXCEL" value="0"></el-option>
                                <el-option label="PPT" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <div v-if="exportform.type==0">
                        <el-form-item label="导出日期" prop="insterdate">
                            <el-date-picker v-model="exportform.insterdate" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" :picker-options="pickerdisabledold">
                            </el-date-picker>
                        </el-form-item>
                    </div>
                    <div v-if="exportform.type==1">
                        <el-col :span="8">
                            <el-form-item label="导出时间" prop="insterdate">
                                <!-- <el-date-picker v-model="exportform.insterdate" type="week" placeholder="选择日期" format="yyyy 年 WW 周" :picker-options="pickerdisabledold">
                                </el-date-picker> -->
                                <el-date-picker v-if="timetype==0" v-model="exportform.insterdate" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" :picker-options="pickerdisabledold">
                                </el-date-picker>
                                <el-date-picker v-else v-model="exportform.insterdate" type="month" format="yyyy 年 MM 月" value-format="yyyy-MM" placeholder="选择月" :picker-options="pickerdisabledold">
                                </el-date-picker>

                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="邮箱" prop="qqmail">
                                <el-input v-model="exportform.qqmail" placeholder="请输入邮箱"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="类型">
                                <el-switch v-model="timetype" active-color="#13ce66" active-value="1" inactive-color="#409EFF" inactive-value="0" active-text="月" inactive-text="日">
                                </el-switch>
                            </el-form-item>
                        </el-col>
                    </div>

                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="exportmethod" v-loading.fullscreen.lock="fullscreenLoading">导 出</el-button>
                <el-button @click="exportVisible=false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { businessList } from '@/apps/api/business'

import { xcdList, xcdExportExcel, xcdEmailPpt } from '@/apps/api/xcd'
import edit from '@/apps/views/xcd/edit'
export default {
    name: 'xcdlist',
    components: {
        edit
    },
    data () {
        return {
            timetype: 1,
            fullscreenLoading: false,
            reloading: false,
            value1: null,
            // 商家列表
            businesslist: [],
            businessoptions: [],
            exportVisible: false,
            // 日期限制，仅限今天之前
            pickerdisabledold: {
                disabledDate (time) {
                    return time.getTime() > Date.now(); // 如果没有后面的-8.64e7就是可以选择今天的
                },
            },
            loading: false,
            form: {
                businessName: '',
                insterName: '',
                insterdate: '',
                status: 1,
                pageSize: 10,
                pageNum: 1
            },
            exportform: {},
            total: 0,
            xcdlist: [],
            areatreelist: null,
            exportrules: {
                type: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                insterdate: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                qqmail: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
            }
        }
    },
    created () {
        this.form.insterdate = this.getNowFormatDate()

        this.getxcdlist()
    },
    methods: {
        //获取当前日期函数
        getNowFormatDate () {
            let date = new Date(),
                year = date.getFullYear(), //获取完整的年份(4位)
                month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
                strDate = date.getDate() // 获取当前日(1-31)
            if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
            if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0

            return `${year}-${month}-${strDate}`
        },
        // 页大小修改
        sizechange (item) {
            this.form.pageSize = item
            this.getxcdlist()

        },
        // 页码修改
        currentchange (item) {
            this.form.pageNum = item
            this.getxcdlist()

        },
        // 禁止粘贴
        handleFalse () {
            //终止
            return false;
        },
        // 导出
        exportmethod () {
            // 导出EXCel
            this.fullscreenLoading = true;
            if (this.exportform.type == 0) {
                let data = {
                    insterdate: this.exportform.insterdate
                }
                xcdExportExcel(data).then(res => {
                    const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
                    const linkNode = document.createElement('a');
                    linkNode.style.display = 'none';
                    linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                    document.body.appendChild(linkNode);
                    linkNode.click();  //模拟在按钮上的一次鼠标单击
                    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                    document.body.removeChild(linkNode);
                    this.fullscreenLoading = false;
                    this.exportVisible = false
                })
                // 导出ppt
            } else if (this.exportform.type == 1) {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let data = {
                            insterdate: this.exportform.insterdate,
                            qqEamil: this.exportform.qqmail
                        }
                        xcdEmailPpt(data).then(res => {
                            console.log(res)

                        })
                        this.fullscreenLoading = true;
                        this.exportVisible = false
                        this.$message({
                            message: "日倒出一半延迟5-10分钟，月倒出一般延迟半小时左右，如果没有收到邮件请重试",
                        })
                    }
                })

                // console.log(this.exportform.insterdate)
                // Date.prototype.getWeek = function () {
                //     var onejan = new Date(this.getFullYear(), 0, 1);
                //     return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
                // }
                // var date = new Date(this.exportform.insterdate);
                // var year = date.getFullYear();
                // var week = date.getWeek();
                // xcdExportPpt(year, week).then(res => {
                //     const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' })
                //     const linkNode = document.createElement('a');
                //     linkNode.style.display = 'none';
                //     linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                //     document.body.appendChild(linkNode);
                //     linkNode.click();  //模拟在按钮上的一次鼠标单击
                //     URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                //     document.body.removeChild(linkNode);
                //     this.fullscreenLoading = false;
                //     this.exportVisible = false
                // })
            }
        },
        // 商铺联想
        remoteMethod (query) {
            if (query) {
                this.reloading = true;
                let data = {
                    name: query,
                    status: 1,
                };
                businessList(data).then((res) => {
                    this.businesslist = []
                    for (let i = 0; i < res.data.length; i++) {
                        this.businesslist.push({
                            id: res.data[i].id,
                            value: res.data[i].name,
                            label: res.data[i].name + "[" + res.data[i].address + "]",
                            delivery: res.data[i].delivery,
                            address: res.data[i].address,
                            type: res.data[i].type,
                        });
                    }
                    this.reloading = false;
                    this.businessoptions = this.businesslist.filter((item) => {
                        return item.label.indexOf(query) > -1;
                    });
                });
                setTimeout(() => {

                }, 200);
            } else {
                this.businessoptions = [];
            }
        },
        businessclick (item) {
            this.form.businessid = item.id
            this.form.businessaddress = item.address
            this.form.businessdelivery = item.delivery
            this.form.type = item.type
        },
        tableRowClassName ({ row, rowIndex }) {
            if (row.iscupboardc == 0) {
                return 'danger-row';
            }
            return '';
        },
        resetsearch () {
            this.form = {
                businessName: '',
                insterName: '',
                insterdate: '',
                status: 1,
                pageSize: 10,
                pageNum: 1
            },
                this.getxcdlist()
        },
        handleChange (value) {
            this.form.areacode = value[value.length - 1]
        },
        // 获取用户
        getxcdlist () {
            this.loading = true
            xcdList(this.form).then(res => {
                if (res.code == 200) {
                    this.xcdlist = res.data
                    this.total = res.total
                    this.loading = false
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                    this.loading = false
                }
            })
        },
        formatter (row, column) {
            switch (row.isShow) {
                case '0':
                    return "禁用"
                case '1':
                    return "数量评级"
                case '2':
                    return "价格评级"
                case '3':
                    return "全部评级"
            }
        },
        // 打开修改用户页面
        edit (value) {
            setTimeout(() => {
                this.$refs.edit.handleupdate(value)
            }, 1000)
        },
        addxcd () {
            setTimeout(() => {
                this.$refs.edit.handleadd()
            }, 1000)
        },
        copy (id) {

            let url = process.env.VUE_APP_WEB_URL + "/xcdinfo/" + id
            this.$copyText(url).then(e => {
                this.$message({
                    message: "链接已复制",
                    type: 'success'
                })

            }, function (e) {
                this.$message({
                    message: "复制失败请重试或者联系管理员",
                    type: 'danger'
                })
            })
        },
        iscupboardcFormatter (value) {
            switch (value) {
                case '0':
                    return "未达标"
                case '1':
                    return "达标"
                case '2':
                    return "非付费"

            }
        },

    }
}
</script>
<style>
</style>
