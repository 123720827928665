<template>
    <div>
        <el-dialog ref="editDiaLog" :visible.sync="open" :close-on-click-modal="false" :close-on-press-escape="false" :width="dialogWidth" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="auto">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="等级" prop="type">
                            <el-input v-model="form.type" placeholder="请输入等级名" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="品类数量" prop="shopVariety">
                            <el-input v-model="form.shopVariety" placeholder="请输入品类数量">
                            </el-input>

                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="货物数量" prop="shopNumber">
                            <el-input v-model="form.shopNumber" placeholder="请输入货物数量" />
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-form-item label="状态" prop="status">
                            <el-radio-group v-model="form.status" size="small">
                                <el-radio-button label="1">启用</el-radio-button>
                                <el-radio-button label="0">禁用</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">提 交</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { areaList, areaTree } from '@/apps/utils/area'
import { levelUpdate, levelAdd } from '@/apps/api/level'
import { getUUID } from "@/apps/utils/uuid"
export default {
    name: 'leveledit',
    data () {
        return {
            isEdit: false,
            imageUrl: null,
            dialogWidth: '800px',
            open: false,
            form: {},
            rules: {
                type: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                shopVariety: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                shopNumber: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                status: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ]
            }
        }
    },
    methods: {
        handleadd (value) {
            this.open = true
            this.form = {}
            this.form.id = getUUID()
            this.isEdit = false
        },
        handleupdate (value) {
            this.open = true
            this.form = {}
            this.form = value
            this.isEdit = true
        },
        reset () {
            this.form = {
                id: null,
                type: null,
                shopVariety: null,
                shopNumber: null,
                status: null
            }
        },
        getParentList () {
            this.$parent.getlevellist()
        },
        // 取消按钮
        cancel () {
            this.open = false
            this.reset()
            this.getParentList()
        },
        submitForm () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.isEdit) {
                        levelUpdate(this.form).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    message: '修改成功',
                                    type: 'success'
                                })
                                this.getParentList()
                                this.open = false
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        })
                    } else {
                        levelAdd(this.form).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    message: '添加成功',
                                    type: 'success'
                                })
                                this.getParentList()
                                this.open = false
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        })
                    }
                }
            })
        }
    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
