<template>
    <div>
        <div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>商品列表</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="tablelist">

                <el-form :model="form" ref="planQueryForm" :inline="true">
                    <el-row :gutter="10">

                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item label="商品名">
                                <el-input v-model="form.name" style="width: 300px;" placeholder="请输入商品名" clearable size="small" type="text" />
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item label="商品状态">
                                <el-select v-model="form.isShow" placeholder="请选择">
                                    <el-option label="全部评级" value="3"> </el-option>
                                    <el-option label="数量评级" value="2"> </el-option>
                                    <el-option label="价格评级" value="1"> </el-option>
                                    <el-option label="禁用" value="0"> </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item>
                                <el-button type="success" icon="el-icon-search" size="mini" @click="getgoodslist">搜索</el-button>
                                <el-button type="warning" icon="el-icon-refresh-right" size="mini" @click="resetsearch">重置</el-button>
                                <el-button type="primary" icon="el-icon-plus" size="mini" @click="addgoods">新增</el-button>
                                <el-button type="primary" icon="el-icon-plus" size="mini" @click="bpgoodsopen=true">爆品设定</el-button>

                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table :data="goodslist" style="width: 100%" v-loading="loading" :row-class-name="tableRowClassName">
                    <el-table-column label="序号" align="center" type="index" width="60" fixed="left"></el-table-column>
                    <el-table-column prop="name" label="商品名" align="center"></el-table-column>
                    <el-table-column prop="price" label="价格" align="center"></el-table-column>
                    <el-table-column prop="isShow" label="显示类型" align="center" :formatter="goodsFormatter"></el-table-column>
                    <el-table-column prop="describe" label="商品描述" align="center"></el-table-column>
                    <el-table-column prop="lastUpdateName" label="最后一次修改人" align="center" width="120"></el-table-column>
                    <el-table-column prop="lastUpdateDate" label="最后一次修改时间" align="center" width="160"></el-table-column>
                    <el-table-column fixed="right" label="操作" align="center" width="220">
                        <template slot-scope="scope">
                            <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination v-show="total > 0" @size-change="sizechange" @current-change="currentchange" :current-page="form.pageNum" :page-sizes="[10, 15, 20]" :page-size="form.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog width="600px" title="爆品列表" :visible.sync="bpgoodsopen">
            <el-form :model="form">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="商品名">
                            <el-select v-model="bpform.goodsname" filterable remote placeholder="请输入商品名" :remote-method="remoteMethod" :loading="loading" @paste.native.capture.prevent="handleFalse">
                                <el-option v-for="item in bpgoodschooseoptions" :key="item.value" :label="item.value" :value="item.value" @click.native="goodsclick(item)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="primary" icon="el-icon-plus" size="mini" @click="addbp">添加</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <el-table :data="bpgoodslist" style="width: 100%" v-loading="loading">
                <el-table-column label="序号" align="center" type="index" width="60" fixed="left"></el-table-column>
                <el-table-column prop="goodsname" label="商品名" align="center"></el-table-column>
                <el-table-column fixed="right" label="操作" align="center" width="100">
                    <template slot-scope="scope">
                        <el-button @click="delbp(scope.row.id)" type="text" size="small" class="dangertext">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="bpgoodsopen=false">取 消</el-button>
            </div>
        </el-dialog>
        <edit ref="edit"></edit>
    </div>
</template>
<script>
import { getUUID } from "@/apps/utils/uuid"
import { goodsList } from '@/apps/api/goods'
import { bpgoodsList, delbpgoods, bpgoodsAdd } from '@/apps/api/bpgoods'
import edit from '@/apps/views/goods/edit'
export default {
    name: 'goodslist',
    components: {
        edit
    },
    data () {
        return {
            bpform: {

            },
            bpgoodslist: [],
            bpgoodsopen: false,
            loading: false,
            form: {
                name: '',
                isShow: '',
                pageSize: 10,
                pageNum: 1
            },
            total: 0,
            goodslist: [],
            areatreelist: null,
            bpgoodschooselist: [],
            bpgoodschooseoptions: []

        }
    },
    created () {
        this.getgoodslist()
        this.getbpgoodslist()

    },
    methods: {
        // 禁止粘贴
        handleFalse () {
            //终止
            return false;
        },
        // 商品联想
        remoteMethod (query) {
            if (query) {
                this.loading = true;
                let data = {
                    name: query,
                };
                goodsList(data).then((res) => {
                    this.bpgoodschooselist = []
                    for (let i = 0; i < res.data.length; i++) {
                        this.bpgoodschooselist.push({
                            id: res.data[i].id,
                            value: res.data[i].name,
                        });
                    }
                    this.loading = false;
                    this.bpgoodschooseoptions = this.bpgoodschooselist.filter((item) => {
                        return item.value.indexOf(query) > -1;
                    });
                });
                setTimeout(() => {

                }, 200);
            } else {
                this.bpgoodschooseoptions = [];
            }
        },
        goodsclick (item) {
            this.bpform.goodsid = item.id
        },
        // 添加爆品
        addbp () {
            if (this.bpform.goodsname != null) {
                this.loading = true
                this.bpform.id = getUUID()
                bpgoodsAdd(this.bpform).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.getbpgoodslist()
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                        this.loading = false
                    }
                })
            }

        },
        // 页大小修改
        sizechange (item) {
            this.form.pageSize = item
            this.getgoodslist()

        },
        // 页码修改
        currentchange (item) {
            this.form.pageNum = item
            this.getgoodslist()

        },
        // 状态表格变色
        tableRowClassName ({ row, rowIndex }) {
            if (row.status == 0) {
                return 'danger-row';
            }
            return '';
        },
        resetsearch () {
            this.form = {
                name: '',
                isShow: '',
                pageSize: 10,
                pageNum: 1
            }
            this.getgoodslist()
        },
        handleChange (value) {
            this.form.areacode = value[value.length - 1]
        },
        // 获取商品列表
        getgoodslist (item) {
            this.loading = true
            goodsList(this.form).then(res => {
                if (res.code == 200) {
                    this.goodslist = res.data
                    this.total = res.total
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }
                this.loading = false

            })
        },
        getbpgoodslist () {
            this.loading = true
            let data = {
                pageSize: 0
            }
            bpgoodsList(data).then(res => {
                if (res.code == 200) {
                    this.bpgoodslist = res.data
                }
                this.loading = false
            })
        },
        delbp (id) {
            this.loading = true
            let data = {
                id: id
            }
            delbpgoods(data).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                    this.loading = false
                }
            })
        },

        goodsFormatter (row, column) {
            switch (row.isShow) {
                case '0':
                    return "禁用"
                case '1':
                    return "价格评级"
                case '2':
                    return "数量评级"
                case '3':
                    return "全部评级"
            }
        },
        // 打开修改用户页面
        edit (value) {
            setTimeout(() => {
                this.$refs.edit.handleupdate(value)
            }, 1000)
        },
        addgoods () {
            setTimeout(() => {
                this.$refs.edit.handleadd()
            }, 1000)
        }

    }
}
</script>
<style>
</style>
