<template>
    <div>
        <div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>首页</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="tablelist">

                <el-row :gutter="20">
                    <el-divider content-position="left">系统信息</el-divider>
                    <el-col :span="5">
                        <div>
                            <el-statistic title="登陆人">
                                <template slot="formatter"> {{ user.name  }} </template>
                            </el-statistic>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div>
                            <el-statistic title="时间">
                                <template slot="formatter"> {{ nowTime }} </template>
                            </el-statistic>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div>
                            <el-statistic title="商家数">
                                <template slot="formatter"> {{ businesstotal  }} </template>
                            </el-statistic>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div>
                            <el-statistic title="商品数">
                                <template slot="formatter"> {{ goodstotal }} </template>
                            </el-statistic>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div>
                            <el-statistic title="用户数">
                                <template slot="formatter"> {{ usertotal }} </template>
                            </el-statistic>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-divider content-position="left">今日巡查状况</el-divider>
                    <el-col :span="5">
                        <div>
                            <el-statistic title="总巡查数量">
                                <template slot="formatter"> {{ xcdtotal  }} </template>
                            </el-statistic>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div>
                            <el-statistic title="合格数量">
                                <template slot="formatter"> {{ hgxcd }} </template>
                            </el-statistic>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div>
                            <el-statistic title="不合格数量">
                                <template slot="formatter"> {{ bhgxcd }} </template>
                            </el-statistic>
                        </div>
                    </el-col>
                    <el-col :span="5">
                        <div>
                            <el-statistic title="非付费数量">
                                <template slot="formatter"> {{ freexcd }} </template>
                            </el-statistic>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div>
                            <el-statistic title="个人填写巡查单数量">
                                <template slot="formatter"> {{ userxcd }} </template>
                            </el-statistic>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-divider content-position="left">商家分布图</el-divider>
                    <MapContainer ref="MapContainer" :type="0"></MapContainer>
                </el-row>
            </div>
        </div>

    </div>
</template>
<script>
import { userList } from '@/apps/api/user'
import { goodsList } from '@/apps/api/goods'

import { xcdList, } from '@/apps/api/xcd'
import MapContainer from "@/apps/components/MapContainer.vue"
import { businessList } from '@/apps/api/business'

export default {
    name: 'index',
    components: {
        MapContainer,
    },
    data () {
        return {
            usertotal: null,
            businesstotal: null,
            goodstotal: null,
            userxcd: null,
            xcdtotal: null,
            hgxcd: null,
            bhgxcd: null,
            freexcd: null,
            nowTime: null,
            user: null,
            businesslist: []
        }
    },
    mounted () {
        this.Initialize()
        this.getNowTime();
        this.getxcdinfo()
        this.getsysteminfo()
    },
    methods: {
        // 查看系统信息
        getsysteminfo () {
            // 商户数
            let data = {
                pageNum: 0
            }
            businessList(data).then(res => {
                this.businesstotal = res.total
            })
            // 商户数
            userList(data).then(res => {
                this.usertotal = res.total
            })
            // 商品数
            goodsList(data).then(res => {
                this.goodstotal = res.total
            })
        },
        // 查询 今日巡查单数量
        getxcdinfo () {
            // 当前登陆人
            let data1 = {
                insterid: this.user.id,
                insterdate: this.getdate(),
                status: 1
            }
            xcdList(data1).then(res => {
                this.userxcd = res.total
            })
            // 今日总数
            let data2 = {
                insterdate: this.getdate(),
                status: 1
            }
            xcdList(data2).then(res => {
                this.xcdtotal = res.total
            })
            // 今日合格
            let data3 = {
                iscupboardc: 1,
                insterdate: this.getdate(),
                status: 1
            }
            xcdList(data3).then(res => {
                this.hgxcd = res.total
            })
            // 今日不合格
            let data4 = {
                iscupboardc: 0,
                insterdate: this.getdate(),
                status: 1
            }
            xcdList(data4).then(res => {
                this.bhgxcd = res.total
            })
            // 今日免费
            let data5 = {
                iscupboardc: 2,
                insterdate: this.getdate(),
                status: 1
            }
            xcdList(data5).then(res => {
                this.freexcd = res.total
            })
        },
        getNowTime () {
            let speed = 1000
            let that = this
            let theNowTime = function () {
                that.nowTime = that.timeNumber()
            }
            setInterval(theNowTime, speed)
        },
        getdate () {
            let today = new Date()
            let date = today.getFullYear() + '-' + this.twoDigits(today.getMonth() + 1) + '-' + this.twoDigits(today.getDate())
            return date
        },
        timeNumber () {
            let today = new Date()
            let date = today.getFullYear() + '-' + this.twoDigits(today.getMonth() + 1) + '-' + this.twoDigits(today.getDate())
            let time = this.twoDigits(today.getHours()) + ':' + this.twoDigits(today.getMinutes()) + ':' + this.twoDigits(today.getSeconds())
            return date + '  ' + time
        },
        twoDigits (val) {
            if (val < 10) return '0' + val
            return val
        },
        Initialize () {
            this.user = JSON.parse(sessionStorage.getItem('user'))
            let data = {
                status: 1
            }
            businessList(data).then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    let data = {
                        market: res.data[i].mapaddress,
                        name: res.data[i].name,
                        address: res.data[i].address
                    }
                    this.businesslist.push(data)
                }
                this.$refs.MapContainer.moresetMarket(this.businesslist)

            })
        }



    }
}
</script>
<style>
</style>
