/* eslint-disable space-before-function-paren */
import request from '@/apps/utils/request'

// 用户查询
export function userList(data) {
    return request({
        url: '/mn/user/list',
        method: 'POST',
        data: data
    })
}


// 用户查询
export function userUpdate(data) {
    return request({
        url: 'mn/user/update',
        method: 'POST',
        data: data
    })
}