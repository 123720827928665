<template>
    <div>
        <el-drawer ref="editDiaLog" :visible.sync="open" :close-on-click-modal="false" :close-on-press-escape="false" :size="dialogWidth" :title="title">
            <el-form ref="form" :model="form" :rules="rules" label-width="auto" style="margin: 10px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="商铺名" prop="name">
                            <el-input v-model="form.name" placeholder="请输入商铺名" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="地区编码" prop="areacode">
                            <el-cascader v-model="form.areacode" :props="{label:'areaname',value:'areacode',checkStrictly: 'true'}" :show-all-levels="false" :options="areatreelist" @change="areaChange"></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="详细地址">
                            <el-input v-model="address" placeholder="请输入地区详细地址" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="地图坐标" prop="mapaddress">
                            <el-input placeholder="请输入地图坐标" v-model="form.mapaddress" class="input-with-select" disabled>
                                <el-button slot="append" icon="el-icon-map-location" type="primary" @click="openmap"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="完整地区" prop="address">
                            <el-input v-model="form.address" placeholder="自动生成无需填写" disabled />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="商家类型" prop="type">
                            <el-select v-model="form.type" placeholder="请选择">
                                <el-option v-for="item in leveloptions" :key="item.value" :label="item.type" :value="item.id">
                                </el-option>
                            </el-select>
                            <!-- <el-input v-model="form.type" placeholder="请输入商家类型" /> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="配送" prop="delivery">
                            <el-input v-model="form.delivery" placeholder="请输入配送" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="业务" prop="work">
                            <el-input v-model="form.work" placeholder="请输入业务" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="状态" prop="status">
                            <el-radio-group v-model="form.status" size="small">
                                <el-radio-button label="1">启用</el-radio-button>
                                <el-radio-button label="0">禁用</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <Upload ref="Upload" :domain="domain" :business="business" :label="'商家照片'"></Upload>

                </el-row>
            </el-form>
            <div class="demo-drawer__footer">
                <el-button type="primary" @click="submitForm">提 交</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
            <!-- 地图 -->
            <el-dialog width="80%" title="地图" :visible.sync="mapVisible" append-to-body>
                <MapContainer ref="MapContainer" :type="1"></MapContainer>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="getPoint">提 交</el-button>
                    <el-button @click="mapVisible=false">取 消</el-button>
                </div>
            </el-dialog>
        </el-drawer>
    </div>
</template>

<script>
import { areaTree, areaName } from '@/apps/utils/area'
import { levelList } from '@/apps/api/level'
import { businessUpdate, businessAdd } from '@/apps/api/business'
import { getUUID } from "@/apps/utils/uuid"
import MapContainer from "@/apps/components/MapContainer.vue"
import Upload from "@/apps/components/File/upload.vue"
export default {
    name: 'businessedit',
    components: {
        MapContainer,
        Upload
    },
    data () {
        return {
            // 上传参数
            domain: null,
            business: null,
            mapmarket: null,
            // 地区码返回的完整路径
            address: null,
            title: null,
            isEdit: false,
            imageUrl: null,
            dialogWidth: '1000px',
            open: false,
            mapVisible: false,
            form: {},
            // 地区下拉框数据
            areatreelist: null,
            //等级列表
            leveloptions: [],

            rules: {
                name: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                areacode: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                mapaddress: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                delivery: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                work: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                status: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ]
            }
        }
    },
    created () {
        this.getLevel()
        this.getArea()
    },
    mounted () {

    },
    watch: {
        'form.areacode' (newVal, oldVal) {
            if (newVal) {
                this.backAddress(newVal)
            }
        },
        address () {
            this.backAddress(this.form.areacode)
        }
    },
    methods: {
        // 获取等级列表参数
        getLevel () {
            let data = {
                status: 1
            }
            levelList(data).then(res => {
                this.leveloptions = res.data
            })
        },
        // 获取地区列表参数
        getArea () {
            let data = {
                areacode: 620100000000
            }
            areaTree(data).then(res => {
                this.areatreelist = res.data
            })
        },
        // 获取完整路径
        backAddress (value) {
            let data = {
                areacode: value
            }
            areaName(data).then(res => {
                this.form.address = res + '-' + this.address
            })
        },

        // 初始化地图，有坐标就绘制标记
        openmap () {
            this.mapVisible = true
            if (this.form.mapaddress) {
                let a = this.form.mapaddress.split(',')
                setTimeout(() => {
                    this.$refs.MapContainer.setMarker(a)
                }, 1000)
            }
        },
        // 返回坐标
        getPoint () {
            if (this.$refs.MapContainer.lnglat != []) {
                this.form.mapaddress = this.$refs.MapContainer.lnglat[0] + ',' + this.$refs.MapContainer.lnglat[1]
                this.mapVisible = false
            }
        },
        // 选择地去
        areaChange (value) {
            if (value) {
                this.form.areacode = value[value.length - 1]
            }
        },
        handleadd () {
            this.title = '商家新增'
            this.open = true
            this.form = {}
            this.form.id = getUUID()
            this.domain = this.form.id
            this.business = this.form.id
            this.isEdit = false
        },

        handleupdate (value) {
            this.title = '商家修改'
            this.open = true
            this.form = {}
            this.form = value
            this.domain = this.form.id
            this.business = this.form.id
            this.address = this.form.address.substr(this.form.address.indexOf("-") + 1, this.form.address.length)
            this.isEdit = true
        },

        reset () {
            this.form = {
            }
        },
        getParentList () {
            this.$parent.getbusinesslist()
        },
        // 取消按钮
        cancel () {
            this.open = false
            this.reset()
            this.getParentList()
        },
        submitForm () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.isEdit) {
                        businessUpdate(this.form).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    message: '修改成功',
                                    type: 'success'
                                })
                                this.getParentList()
                                this.open = false
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        })
                    } else {
                        businessAdd(this.form).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    message: '添加成功',
                                    type: 'success'
                                })
                                this.getParentList()
                                this.open = false
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        })
                    }
                }
            })
        }
    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
#map-container {
    overflow: hidden;
    width: 500px;
    height: 500px;
    margin: 0;
}
</style>
