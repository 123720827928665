/* eslint-disable space-before-function-paren */
import request from '@/apps/utils/request'

// 商家查询
export function goodsList(data) {
    return request({
        url: 'mn/goodsInfo/list',
        method: 'POST',
        data: data
    })
}

// 商家修改
export function goodsUpdate(data) {
    return request({
        url: 'mn/goodsInfo/update',
        method: 'POST',
        data: data
    })
}

// 商家修改
export function goodsAdd(data) {
    return request({
        url: 'mn/goodsInfo/add',
        method: 'POST',
        data: data
    })
}