<template>
    <div>
        <el-drawer ref="editDiaLog" :visible.sync="open" :close-on-click-modal="false" title="编辑巡查单" :close-on-press-escape="false" :size="dialogWidth" :title="title">
            <div class="demo-drawer__content">
                <el-form ref="form" :model="form" :rules="rules" label-width="auto" style="margin: 10px">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="基础信息" name="first">
                            <el-row>
                                <el-divider content-position="left">表单信息</el-divider>

                                <el-col :span="24">
                                    <el-form-item label="业务员" prop="instername">
                                        <el-input v-model="form.instername" placeholder="业务员" disabled />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="填写日期" prop="insterdate">
                                        <el-input v-model="form.insterdate" placeholder="填写日期" disabled />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-divider content-position="left">商家信息</el-divider>
                                <el-col :span="24">
                                    <el-form-item label="商铺名" prop="businessname">
                                        <el-select v-model="form.businessname" filterable remote placeholder="请输入商铺名" :remote-method="remoteMethod" :loading="loading" @paste.native.capture.prevent="handleFalse" style="width: 650px;">
                                            <el-option v-for="item in businessoptions" :key="item.value" :label="item.label" :value="item.value" @click.native="businessclick(item)">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="商铺地址" prop="businessaddress">
                                        <el-input v-model="form.businessaddress" placeholder="请选择商铺" disabled />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="商铺业务" prop="businessdelivery">
                                        <el-input v-model="form.businessdelivery" placeholder="请选择商铺" disabled />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="商家类型" prop="type">
                                        <el-select v-model="form.type" placeholder="请选择商铺" disabled>
                                            <el-option v-for="item in leveloptions" :key="item.value" :label="item.type+'['+item.shopVariety+'/'+item.shopNumber+']'" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <Upload ref="Upload" :domain="domainsj" :business="business" :label="'商家照片'"></Upload>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="产品数据" name="second">
                            <el-row>
                                <el-divider content-position="left">SKU信息</el-divider>
                                <el-col :span="24">
                                    <el-form-item label="SKU_MN" prop="skuMn">
                                        <el-input v-model="form.skuMn" placeholder="请输入SKU_MN" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="SKU_YL" prop="skuYl">
                                        <el-input v-model="form.skuYl" placeholder="请输入SKU_YL" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="SKU_ZY" prop="skuZy">
                                        <el-input v-model="form.skuZy" placeholder="请输入SKU_ZY" />
                                    </el-form-item>
                                </el-col>
                                <Upload ref="Upload" :domain="domainsku" :business="business" :label="'SKU照片'"></Upload>
                            </el-row>
                            <el-row>
                                <el-divider content-position="left">单包信息</el-divider>
                                <el-col :span="24">
                                    <el-form-item label="单包_MN" prop="dMn">
                                        <el-input v-model="form.dMn" placeholder="请输入单包_MN" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="单包_YL" prop="dYl">
                                        <el-input v-model="form.dYl" placeholder="请输入单包_YL" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="单包_ZY" prop="dZy">
                                        <el-input v-model="form.dZy" placeholder="请输入单包_ZY" />
                                    </el-form-item>
                                </el-col>
                                <Upload ref="Upload" :domain="domaindb" :business="business" :label="'单包照片'"></Upload>
                            </el-row>
                            <el-row>
                                <el-divider content-position="left">整箱信息</el-divider>
                                <el-col :span="24">
                                    <el-form-item label="整箱_MN" prop="zMn">
                                        <el-input v-model="form.zMn" placeholder="请输入整箱_MN" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="整箱_YL" prop="zYl">
                                        <el-input v-model="form.zYl" placeholder="请输入整箱_YL" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="整箱_ZY" prop="zZy">
                                        <el-input v-model="form.zZy" placeholder="请输入整箱_ZY" />
                                    </el-form-item>
                                </el-col>
                                <Upload ref="Upload" :domain="domainzx" :business="business" :label="'整箱照片'"></Upload>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="检查指标" name="third">
                            <el-row>
                                <el-divider content-position="left">数量检查</el-divider>
                                <el-col :span="12" v-for="item in numberceckbusiness">
                                    <el-form-item :label="item.name">
                                        <el-input v-model="item.value" :placeholder="item.describe" @blur="bptotal" :disabled="item.id=='0000000000000000'" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-divider content-position="left">价格检查</el-divider>
                                <el-col :span="12" v-for="item in moneycheckbusiness">
                                    <el-form-item :label="item.name">
                                        <el-input v-model="item.value" :placeholder="item.price" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-divider content-position="left">贴标专项</el-divider>
                                <el-col :span="12">
                                    <el-form-item label="爆品数量">
                                        <el-input-number v-model="form.tbzxBpNumber" controls-position="right" :min="0"></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="贴标数量">
                                        <el-input-number v-model="form.tbzxNumber" controls-position="right" :min="0"></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="贴标达标率" prop="">
                                        <el-input v-model="form.tbzxComplianceRate" placeholder="请输入数量" disabled />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                        </el-tab-pane>
                        <el-tab-pane label="检查结论" name="four">
                            <el-row>
                                <el-divider content-position="left">检查结论</el-divider>
                                <el-col :span="12">
                                    <el-form-item label="是否短保">
                                        <el-radio-group v-model="form.isshortb">
                                            <el-radio label="0" @click.native="ispass()" class="dangertext">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                            <el-radio label="2">非付费</el-radio></el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="价格是否达标">
                                        <el-radio-group v-model="form.isprice">
                                            <el-radio label="0" @click.native="ispass()" class="dangertext">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                            <el-radio label="2">非付费</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="是否有跨年产品">
                                        <el-radio-group v-model="form.isoleyear">
                                            <el-radio label="0">否</el-radio>
                                            <el-radio label="1" @click.native="ispass()" class="dangertext">是</el-radio>
                                            <el-radio label="2">非付费</el-radio></el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="品相是否达标">
                                        <el-radio-group v-model="form.iscondition">
                                            <el-radio label="0" @click.native="ispass()" class="dangertext">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                            <el-radio label="2">非付费</el-radio></el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="囤货是否达标">
                                        <el-radio-group v-model="form.isstoregoods">
                                            <el-radio label="0" @click.native="ispass()" class="dangertext">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                            <el-radio label="2">非付费</el-radio></el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="工字柜是否达标">
                                        <el-radio-group v-model="form.iscupboardg">
                                            <el-radio label="0" @click.native="ispass()" class="dangertext">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                            <el-radio label="2">非付费</el-radio></el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="陈列店是否达标">
                                        <el-radio-group v-model="form.iscupboardc">
                                            <el-radio label="0" class="dangertext">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                            <el-radio label="2">非付费</el-radio></el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="价格签使用">
                                        <el-radio-group v-model="form.ismarkprice">
                                            <el-radio label="0">无</el-radio>
                                            <el-radio label="1">有</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="是否有陈列载体">
                                        <el-radio-group v-model="form.display">
                                            <el-radio label="0">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                            <el-radio label="2">部分</el-radio></el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="费用给付">
                                        <el-radio-group v-model="form.cost">
                                            <el-radio label="0">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                            <el-radio label="2">非付费</el-radio></el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="是否第一陈列">
                                        <el-radio-group v-model="form.isonec">
                                            <el-radio label="0">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                            <el-radio label="2">非付费</el-radio></el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="临期\过期">
                                        <el-radio-group v-model="form.isexpirydate">
                                            <el-radio label="1">无</el-radio>
                                            <el-radio label="0" @click.native="ispass()" class="dangertext">过期</el-radio>
                                            <el-radio label="2">临期</el-radio></el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="是否有安慕希">
                                        <el-radio-group v-model="form.isamx">
                                            <el-radio label="0">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="爱克林是否铺市">
                                        <el-radio-group v-model="form.aklShopTheMarket">
                                            <el-radio label="0">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="爱克林是否赠绑定[≥400ml]">
                                        <el-radio-group v-model="form.aklGiftBundle">
                                            <el-radio label="0">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="精选是否铺市">
                                        <el-radio-group v-model="form.jxShopTheMarket">
                                            <el-radio label="0">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="216是否达标">
                                        <el-radio-group v-model="form.is216">
                                            <el-radio label="0">否</el-radio>
                                            <el-radio label="1">是</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="24">
                                    <el-form-item label="备注">
                                        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="form.remarks" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>

                </el-form>
                <div class="demo-drawer__footer">
                    <el-button type="primary" @click="submitForm">提 交</el-button>
                    <el-button @click="cancel">取 消</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { xcdUpdate, xcdAdd } from '@/apps/api/xcd'
import { businessList } from '@/apps/api/business'
import { levelList } from '@/apps/api/level'
import Upload from "@/apps/components/File/upload.vue"
import { getUUID } from "@/apps/utils/uuid"
import { goodsList } from '@/apps/api/goods'
import { bpgoodsList } from '@/apps/api/bpgoods'

export default {
    name: 'xcdedit',
    components: {
        Upload
    },
    data () {
        return {
            numbercecklist: [],
            numberceckbusiness: [],
            moneychecklst: [],
            moneycheckbusiness: [],
            activeName: 'first',
            title: null,
            loading: false,
            isEdit: false,
            imageUrl: null,
            dialogWidth: '1000px',
            open: false,
            form: {},
            // 商家列表
            businesslist: [],
            businessoptions: [],
            //等级列表
            leveloptions: [],
            // 附件
            business: null,
            domainsj: null,
            domainsku: null,
            domaindb: null,
            domainzx: null,
            rules: {
                businessname: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                businessaddress: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                businessdelivery: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
                skuMn: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ], skuYl: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ], skuZy: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ], dMn: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ], dYl: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ], dZy: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ], zMn: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ], zYl: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ], zZy: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ], businessdelivery: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
                isshortb: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ], isprice: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ], isoleyear: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ], iscondition: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ], isstoregoods: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ], iscupboardg: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ], iscupboardc: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ], ismarkprice: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ], display: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ], cost: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ], isonec: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ], isexpirydate: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ], isamx: [
                    { required: true, message: '不能为空', trigger: 'change' }
                ],
            }
        }
    },
    created () {
        this.getLevel()
        this.goods()
    },
    watch: {
        "form.tbzxBpNumber" () {
            this.tbzxBpNumbercheck()
        },
        "form.tbzxNumber" () {
            this.tbzxBpNumbercheck()
        }
    },
    methods: {
        tbzxBpNumbercheck () {
            this.form.tbzxComplianceRate = ((this.form.tbzxNumber / this.form.tbzxBpNumber) * 100).toFixed(2) + "%"
        },
        bptotal () {
            let result = this.numberceckbusiness.filter(item1 => this.form.bpId.find(item2 => item1.id === item2.goodsid));
            let total = 0
            for (let i = 0; i < result.length; i++) {
                total = total + parseInt(result[i].value)
            }
            let obj = this.numberceckbusiness.find(item => item.id == '0000000000000000');
            if (obj) {
                obj.value = total;
            }
        },
        getbpgoodslist () {
            this.loading = true
            let data = {
                pageSize: 0
            }
            bpgoodsList(data).then(res => {
                if (res.code == 200) {
                    this.form.bpId = res.data
                    if (this.form.bpId.length > 0) {
                        let data = {
                            id: "0000000000000000",
                            name: '爆品合计',
                            describe: '自动计算无需填写',
                            value: "",
                        }
                        this.numberceckbusiness.push(data)
                    }
                }
            })
        },
        // 点击否的选项
        ispass () {
            this.form.iscupboardc = '0'
        },
        // 初始化价格、数量检查列表
        goods () {
            // 价格
            var list1
            let data1 = {
                isShow: 1,
                pageNum: 0
            }
            goodsList(data1).then(res => {
                list1 = res.data
            })
            // 数量
            var list2
            let data2 = {
                isShow: 2,
                pageNum: 0
            }
            goodsList(data2).then(res => {
                list2 = res.data
            })
            // 全部
            var list3
            let data3 = {
                isShow: 3,
                pageNum: 0
            }
            goodsList(data3).then(res => {
                list3 = res.data
            })
            setTimeout(() => {
                this.numbercecklist = list2.concat(list3)
                this.moneychecklst = list1.concat(list3)
            }, 2000)
        },
        // 获取等级列表参数
        getLevel () {
            let data = {
                status: 1
            }
            levelList(data).then(res => {
                this.leveloptions = res.data
            })
        },
        // 商铺联想
        remoteMethod (query) {
            if (query) {
                this.loading = true;
                let data = {
                    name: query,
                    status: 1,
                };
                businessList(data).then((res) => {
                    this.businesslist = []
                    for (let i = 0; i < res.data.length; i++) {
                        this.businesslist.push({
                            id: res.data[i].id,
                            value: res.data[i].name,
                            label: res.data[i].name + "[" + res.data[i].address + "]",
                            delivery: res.data[i].delivery,
                            address: res.data[i].address,
                            type: res.data[i].type,
                        });
                    }
                    this.loading = false;
                    this.businessoptions = this.businesslist.filter((item) => {
                        return item.label.indexOf(query) > -1;
                    });
                });
                setTimeout(() => {

                }, 200);
            } else {
                this.businessoptions = [];
            }
        },
        businessclick (item) {
            this.form.businessid = item.id
            this.form.businessaddress = item.address
            this.form.businessdelivery = item.delivery
            this.form.type = item.type
        },
        // 禁止粘贴
        handleFalse () {
            //终止
            return false;
        },
        handleadd (value) {
            this.open = true
            this.form = {}
            this.form.id = getUUID()
            this.business = this.form.id
            this.domainsj = this.form.id + 'sj'
            this.domainsku = this.form.id + 'sku'
            this.domaindb = this.form.id + 'db'
            this.domainzx = this.form.id + 'zx'
            this.isEdit = false
            this.title = "巡查单新增"
            this.getbpgoodslist()
            for (let i = 0; i < this.numbercecklist.length; i++) {
                let data = {
                    id: this.numbercecklist[i].id,
                    name: this.numbercecklist[i].name,
                    describe: this.numbercecklist[i].describe,
                    value: "",
                }
                this.numberceckbusiness.push(data)
            }
            for (let i = 0; i < this.moneychecklst.length; i++) {
                let data = {
                    id: this.moneychecklst[i].id,
                    name: this.moneychecklst[i].name,
                    price: this.moneychecklst[i].price,
                    value: "",
                }
                this.moneycheckbusiness.push(data)
            }

            this.form.status = 1
        },
        handleupdate (value) {
            this.open = true
            this.form = {}
            this.form = value
            console.log(this.form)
            console.log(this.form.is216)

            this.business = this.form.id
            this.domainsj = this.form.id + 'sj'
            this.domainsku = this.form.id + 'sku'
            this.domaindb = this.form.id + 'db'
            this.domainzx = this.form.id + 'zx'
            this.form.bpId = JSON.parse(this.form.bpId)
            this.numberceckbusiness = JSON.parse(this.form.numberceckbusiness)
            this.moneycheckbusiness = JSON.parse(this.form.moneycheckbusiness)
            this.isEdit = true
            this.title = "巡查单修改"
        },
        reset () {
            this.form = {
            }
        },
        getParentList () {
            this.$parent.getxcdlist()
        },
        // 取消按钮
        cancel () {
            this.open = false
            this.reset()
            this.getParentList()
        },
        submitForm () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.form.bpId = JSON.stringify(this.form.bpId)
                    this.form.numberceckbusiness = JSON.stringify(this.numberceckbusiness)
                    this.form.moneycheckbusiness = JSON.stringify(this.moneycheckbusiness)
                    if (this.isEdit) {
                        xcdUpdate(this.form).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    message: '修改成功',
                                    type: 'success'
                                })
                                this.getParentList()
                                this.open = false
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        })
                    } else {
                        xcdAdd(this.form).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    message: '添加成功',
                                    type: 'success'
                                })
                                this.getParentList()
                                this.open = false
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        })
                    }
                }
            })
        }
    }
}
</script>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
.demo-drawer__content {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.demo-drawer__footer {
    display: flex;
}
/* .el-drawer__body {
    padding: 20px;
} */
</style>
