/* eslint-disable space-before-function-paren */
import request from '@/apps/utils/request'

// 商家查询
export function businessList(data) {
    return request({
        url: '/mn/business/list',
        method: 'POST',
        data: data
    })
}

// 商家修改
export function businessUpdate(data) {
    return request({
        url: 'mn/business/update',
        method: 'POST',
        data: data
    })
}

// 商家修改
export function businessAdd(data) {
    return request({
        url: 'mn/business/add',
        method: 'POST',
        data: data
    })
}