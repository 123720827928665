<template>
    <div class="container" v-loading.fullscreen.lock="fullscreenLoading">
        <el-header>
            <el-row type="flex" class="row-bg" justify="space-between">
                <el-col :span="6" style="margin-left:20px;line-height:  60px;font-size: 28px;letter-spacing:10px;color: white;">
                    蒙牛巡查台账管理后台
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple-light"></div>
                </el-col>
                <el-col :xl="2" :xs="8">
                    <div class="grid-content bg-purple" style="line-height:  60px;"><router-link to="/login" style="color: white !important"><i class="el-icon-s-promotion"></i>登陆系统</router-link></div>
                </el-col>
            </el-row>
        </el-header>
        <main style="padding: 40px;">
            <el-divider content-position="left">基础信息</el-divider>

            <el-row :gutter="20">
                <el-col :xl="6" :xs="24">
                    <div style="margin-top: 10px;">
                        <el-statistic title="巡查门店">
                            <template slot="formatter"> {{ scdinfo.businessname }} </template>
                        </el-statistic>
                    </div>
                </el-col>
                <el-col :xl="6" :xs="24">
                    <div style="margin-top: 10px;">
                        <el-statistic title="门店地址">
                            <template slot="formatter"> {{ scdinfo.businessaddress }} </template>
                        </el-statistic>
                    </div>
                </el-col>
                <el-col :xl="6" :xs="24">
                    <div style="margin-top: 10px;">
                        <el-statistic title="巡查结果">
                            <template slot="formatter">
                                <span v-if="scdinfo.iscupboardc==1"><el-tag type="success" size="mini">合格</el-tag></span>
                                <span v-if="scdinfo.iscupboardc==2"><el-tag size="mini">非付费</el-tag></span>
                                <span v-if="scdinfo.iscupboardc==0"><el-tag type="danger" size="mini">不合格</el-tag></span>
                            </template>
                        </el-statistic>
                    </div>
                </el-col>
                <el-col :xl="6" :xs="24">
                    <div style="margin-top: 10px;">
                        <el-statistic title="巡查时间">
                            <template slot="formatter"> {{ scdinfo.instername }}-{{ scdinfo.insterdate }} </template>
                        </el-statistic>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 20px;">
                <el-col :xl="6" :xs="24">
                    <div style="margin-top: 10px;">
                        <el-statistic title="门店类型">
                            <template slot="formatter"> {{scdinfo.type}} </template>
                        </el-statistic>
                    </div>
                </el-col>
                <el-col :xl="6" :xs="24">
                    <div style="margin-top: 10px;">
                        <el-statistic title="门店业务">
                            <template slot="formatter"> {{scdinfo.business.work}} </template>
                        </el-statistic>
                    </div>
                </el-col>
                <el-col :xl="6" :xs="24">
                    <div style="margin-top: 10px;">
                        <el-statistic title="门店配送">
                            <template slot="formatter"> {{scdinfo.business.delivery}}</template>
                        </el-statistic>
                    </div>
                </el-col>
                <el-col :xl="6" :xs="24">
                    <div style="margin-top: 10px;">
                        <el-statistic title="最后修改">
                            <template slot="formatter"> {{ scdinfo.lastupdatename }}-{{ scdinfo.lastupdatedate }}</template>
                        </el-statistic>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-divider content-position="left">巡查详情</el-divider>
                <el-descriptions class="margin-top" title="检查结论" :column="columnnum" border>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            是否短保
                        </template>
                        <span v-if="scdinfo.isshortb==1"><el-tag type="success">是</el-tag></span>
                        <span v-if="scdinfo.isshortb==2"><el-tag>非付费</el-tag></span>
                        <span v-if="scdinfo.isshortb==0"><el-tag type="danger">否</el-tag></span>

                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            价格是否达标
                        </template>
                        <span v-if="scdinfo.isprice==1"><el-tag type="success">是</el-tag></span>
                        <span v-if="scdinfo.isprice==2"><el-tag>非付费</el-tag></span>
                        <span v-if="scdinfo.isprice==0"><el-tag type="danger">否</el-tag></span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            是否有跨年产品
                        </template>
                        <span v-if="scdinfo.isoleyear==0"><el-tag type="success">否</el-tag></span>
                        <span v-if="scdinfo.isoleyear==2"><el-tag>非付费</el-tag></span>
                        <span v-if="scdinfo.isoleyear==1"><el-tag type="danger">是</el-tag></span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            品相是否达标
                        </template>
                        <span v-if="scdinfo.iscondition==1"><el-tag type="success">合格</el-tag></span>
                        <span v-if="scdinfo.iscondition==2"><el-tag>非付费</el-tag></span>
                        <span v-if="scdinfo.iscondition==0"><el-tag type="danger">不合格</el-tag></span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            囤货是否达标
                        </template>
                        <span v-if="scdinfo.isstoregoods==1"><el-tag type="success">是</el-tag></span>
                        <span v-if="scdinfo.isstoregoods==2"><el-tag>非付费</el-tag></span>
                        <span v-if="scdinfo.isstoregoods==0"><el-tag type="danger">否</el-tag></span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            工字柜是否达标
                        </template>
                        <span v-if="scdinfo.iscupboardg==1"><el-tag type="success">是</el-tag></span>
                        <span v-if="scdinfo.iscupboardg==2"><el-tag>无</el-tag></span>
                        <span v-if="scdinfo.iscupboardg==0"><el-tag type="danger">否</el-tag></span>
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            价格签使用
                        </template>
                        <span v-if="scdinfo.ismarkprice==1"><el-tag type="success">有</el-tag></span>
                        <span v-if="scdinfo.ismarkprice==0"><el-tag type="danger">无</el-tag></span>
                    </el-descriptions-item><el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            是否有陈列载体
                        </template>
                        <span v-if="scdinfo.display==1"><el-tag type="success">是</el-tag></span>
                        <span v-if="scdinfo.display==2"><el-tag>非付费</el-tag></span>
                        <span v-if="scdinfo.display==0"><el-tag type="danger">否</el-tag></span>
                    </el-descriptions-item><el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            费用给付
                        </template>
                        <span v-if="scdinfo.cost==1"><el-tag type="success">合格</el-tag></span>
                        <span v-if="scdinfo.cost==2"><el-tag>非付费</el-tag></span>
                        <span v-if="scdinfo.cost==0"><el-tag type="danger">不合格</el-tag></span>
                    </el-descriptions-item><el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            是否第一陈列
                        </template>
                        <span v-if="scdinfo.isonec==1"><el-tag type="success">是</el-tag></span>
                        <span v-if="scdinfo.isonec==2"><el-tag>非付费</el-tag></span>
                        <span v-if="scdinfo.isonec==0"><el-tag type="danger">否</el-tag></span>
                    </el-descriptions-item><el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            临期\过期
                        </template>
                        <span v-if="scdinfo.isonec==1"><el-tag type="success">无</el-tag></span>
                        <span v-if="scdinfo.isonec==2"><el-tag type="waring">临期</el-tag></span>
                        <span v-if="scdinfo.isonec==0"><el-tag type="danger">过期</el-tag></span>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            是否有安慕希
                        </template>
                        <span v-if="scdinfo.isamx==0"><el-tag type="success">无</el-tag></span>
                        <span v-if="scdinfo.isamx==1"><el-tag type="danger">有</el-tag></span>
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions style="margin-top: 20px;" title="囤货检查详情" :column="columnnum" border>
                    <el-descriptions-item v-for="item in numberceckbusiness">
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            {{ item.name }}[{{ item.describe }}]
                        </template>
                        {{ item.value }}
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions style="margin-top: 20px;" title="售价检查详情" :column="columnnum" border>
                    <el-descriptions-item v-for="item in moneycheckbusiness">
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            {{ item.name }}[{{ item.price }}]
                        </template>
                        {{ item.value }}
                    </el-descriptions-item>
                </el-descriptions>

                <el-descriptions style="margin-top: 20px;" title="产品数据" :column="columnnum" border>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            SKU_MN
                        </template>
                        {{ scdinfo.skuMn }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            SKU_YL
                        </template>
                        {{ scdinfo.skuYl }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            SKU_ZY
                        </template>
                        {{ scdinfo.skuZy }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            单包_MN
                        </template>
                        {{ scdinfo.dMn }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            单包_YL
                        </template>
                        {{ scdinfo.dYl }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            单包_ZY
                        </template>
                        {{ scdinfo.dZy }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            整箱_MN
                        </template>
                        {{ scdinfo.zMn }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            整箱_YL
                        </template>
                        {{ scdinfo.zYl }}
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            <i class="el-icon-tickets"></i>
                            整箱_ZY
                        </template>
                        {{ scdinfo.zZy }}
                    </el-descriptions-item>
                </el-descriptions>
            </el-row>
            <el-row v-loading="loading">
                <el-col :xl="6" :md="12" :sm="24" style="border-right: 1px solid #DCDFE6;">
                    <el-divider content-position="left">商家照片</el-divider>
                    <el-image style="width: 100px; height: 100px" :src="item" v-for="item in sjimagelist" :preview-src-list="sjimagelist">
                    </el-image>
                </el-col>
                <el-col :xl="6" :md="12" :sm="24" style="border-right: 1px solid #DCDFE6;">
                    <el-divider content-position="left">SKU照片</el-divider>
                    <el-image style="width: 100px; height: 100px" :src="item" v-for="item in skuimagelist" :preview-src-list="skuimagelist">
                    </el-image>

                </el-col>
                <el-col :xl="6" :md="12" :sm="24" style="border-right: 1px solid #DCDFE6;">
                    <el-divider content-position="left">单包照片</el-divider>
                    <el-image style="width: 100px; height: 100px" :src="item" v-for="item in dbimagelist" :preview-src-list="dbimagelist">
                    </el-image>

                </el-col>
                <el-col :xl="6" :md="12" :sm="24">
                    <el-divider content-position="left">整箱照片</el-divider>
                    <el-image style="width: 100px; height: 100px" :src="item" v-for="item in zximagelist" :preview-src-list="zximagelist">
                    </el-image>

                </el-col>
            </el-row>
            <div class="login-footer">
                <!-- <span>&copy; 2023 版权所有</span>&nbsp; -->
                <span> <a href="https://beian.miit.gov.cn/" target="_blank">陇ICP备2023001093号</a></span>
            </div>
        </main>

    </div>
</template>

<script>
import { xcdById } from "@/apps/api/xcd"
import { filelistno, downloadfile } from "@/apps/api/uplaod"
export default {
    data () {
        return {
            loading: false,
            xcdid: null,
            scdinfo: {},
            fullscreenLoading: false,
            numberceckbusiness: [],
            moneycheckbusiness: [],
            sjimagelist: [],
            skuimagelist: [],
            dbimagelist: [],
            zximagelist: []
        };

    },
    created () {
        if (document.documentElement.clientWidth < 600) {
            this.columnnum = 1
        } else {
            this.columnnum = 3
        }
        this.fullscreenLoading = true
        this.xcdid = this.$route.params.pathMatch
        xcdById(this.xcdid).then(res => {
            console.log(res)
            this.scdinfo = res.data
            this.numberceckbusiness = JSON.parse(this.scdinfo.numberceckbusiness)
            this.moneycheckbusiness = JSON.parse(this.scdinfo.moneycheckbusiness)
            this.fullscreenLoading = false
        })
        this.getimglist()
    },
    methods: {
        getimglist () {

            let data1 = {
                business: this.xcdid,
                domain: this.xcdid + "sj",
                status: 1
            }
            filelistno(data1).then(res => {
                console.log(res)
                for (let i = 0; i < res.data.length; i++) {
                    let url = process.env.VUE_APP_SERVICE_URL + '/downloadFile?id=' + res.data[i].id
                    this.sjimagelist.push(url);
                }
            })
            let data2 = {
                business: this.xcdid,
                domain: this.xcdid + "sku",
                status: 1
            }
            filelistno(data2).then(res => {
                console.log(res)
                for (let i = 0; i < res.data.length; i++) {
                    let url = process.env.VUE_APP_SERVICE_URL + '/downloadFile?id=' + res.data[i].id
                    this.skuimagelist.push(url);
                }
            })
            let data3 = {
                business: this.xcdid,
                domain: this.xcdid + "db",
                status: 1
            }
            filelistno(data3).then(res => {
                console.log(res)
                for (let i = 0; i < res.data.length; i++) {
                    let url = process.env.VUE_APP_SERVICE_URL + '/downloadFile?id=' + res.data[i].id
                    this.dbimagelist.push(url);
                }
            })
            let data4 = {
                business: this.xcdid,
                domain: this.xcdid + "zx",
                status: 1
            }
            filelistno(data4).then(res => {
                console.log(res)
                for (let i = 0; i < res.data.length; i++) {
                    let url = process.env.VUE_APP_SERVICE_URL + '/downloadFile?id=' + res.data[i].id
                    this.zximagelist.push(url);
                }
            })
        },
    }
};
</script>
<style lang="scss">
.like {
    cursor: pointer;
    font-size: 25px;
    display: inline-block;
}
.el-menu {
    border-right: none;
    background-color: #fff !important;
}
/* 设置整体布局高度 */
.container {
    height: 100vh;
}

.el-header {
    background-image: url(../../public/assets/bg_1.png);
}
a:link {
    text-decoration: none; /* 指正常的未被访问过的链接*/
}
a:visited {
    text-decoration: none; /*指已经访问过的链接*/
}
a:hover {
    text-decoration: none; /*指鼠标在链接*/
}
a:active {
    text-decoration: none; /* 指正在点的链接*/
}
a {
    color: #999;
}
.login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #999;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
}

.el-descriptions .is-bordered .el-descriptions-item__cell {
    min-width: 70px !important;
}
</style>