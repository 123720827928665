<template>
    <div>
        <div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>用户列表</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="tablelist">

                <el-form :model="userform" ref="planQueryForm" :inline="true">
                    <el-row :gutter="10">

                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item label="手机号">
                                <el-input v-model="userform.phoneNumber" style="width: 300px;" placeholder="请输入手机号" clearable size="small" type="tel" maxlength="11" />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item label="姓名">
                                <el-input v-model="userform.name" style="width: 300px;" placeholder="请输入姓名" clearable size="small" type="text" />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item>
                                <el-button type="success" icon="el-icon-search" size="mini" @click="getuserlist">搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table :data="userlist" style="width: 100%" v-loading="loading" :row-class-name="tableRowClassName">
                    <el-table-column label="序号" align="center" type="index" width="60" fixed="left"></el-table-column>
                    <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                    <el-table-column prop="phoneNumber" label="手机号" align="center"></el-table-column>
                    <el-table-column prop="nickName" label="微信名" align="center"></el-table-column>
                    <el-table-column prop="lastUpdateName" label="最后一次修改人" align="center" width="120"></el-table-column>
                    <el-table-column prop="lastUpdateDate" label="最后一次修改时间" align="center" width="160"></el-table-column>
                    <el-table-column prop="status" label="状态" align="center" :formatter="userstatusFormatter" width="80"> </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center" width="220">
                        <template slot-scope="scope">
                            <el-button @click="useredit(scope.row)" type="text" size="small">编辑</el-button>
                            <el-button @click="updateStatus(scope.row.id,1)" size="small" v-if="scope.row.status==2" type="text" class="warningtext">激活</el-button>
                            <el-button @click="updateStatus(scope.row.id,0)" size="small" v-if="scope.row.status==1" type="text" class="dangertext">禁用</el-button>
                            <el-button @click="updateStatus(scope.row.id,1)" size="small" v-if="scope.row.status==0" type="text" class="succestext">启用</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination v-show="total > 0" @size-change="getuserlist" @current-change="getuserlist" :current-page="userform.pageNum" :page-sizes="[10, 15, 20]" :page-size="userform.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <useredit ref="useredit"></useredit>
    </div>
</template>
<script>
import { userList, userUpdate } from '@/apps/api/user'
import useredit from '@/apps/views/user/useredit'
export default {
    name: 'userlist',
    components: {
        useredit
    },
    data () {
        return {
            loading: false,
            userform: {
                phoneNumber: '',
                name: '',
                pageSize: 10,
                pageNum: 1
            },
            total: 0,
            userlist: [],
            currentDate: new Date()
        }
    },
    created () {
        this.getuserlist()
    },
    methods: {
        tableRowClassName ({ row, rowIndex }) {
            if (row.status == 0) {
                return 'danger-row';
            } else if (row.status == 2) {
                return 'warning-row';
            }
            return '';
        },
        // 获取用户
        getuserlist () {
            this.loading = true
            userList(this.userform).then(res => {
                if (res.code == 200) {
                    this.userlist = res.data
                    this.total = res.total
                    this.loading = false
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                    this.loading = false
                }
            })
        },
        // 状态修改
        updateStatus (id, status) {
            this.loading = true
            let data = {
                id: id,
                status: status,
            }
            userUpdate(data).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                }
                this.getuserlist()
            })
        },
        userstatusFormatter (row, column) {
            switch (row.status) {
                case '0':
                    return "禁用"
                case '1':
                    return "启用"
                case '2':
                    return "待激活"
            }
        },
        // 打开修改用户页面
        useredit (value) {
            setTimeout(() => {
                this.$refs.useredit.handleupdate(value)
            }, 1000)
        },

    }
}
</script>
<style>
</style>
