<template>
    <div>
        <div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>商户列表</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="tablelist">

                <el-form :model="form" ref="planQueryForm" :inline="true">
                    <el-row :gutter="10">

                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item label="店铺名">
                                <el-input v-model="form.name" style="width: 300px;" placeholder="请输入店铺名" clearable size="small" type="text" />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item label="商家类型">
                                <el-input v-model="form.type" style="width: 300px;" placeholder="请输入商家类型" clearable size="small" type="text" />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item label="店铺状态">
                                <el-select v-model="form.status" placeholder="请选择">
                                    <el-option label="启用" value="1"> </el-option>
                                    <el-option label="禁用" value="0"> </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item label="店铺地区">
                                <el-cascader v-model="form.areacode" style="width: 300px;" :props="{label:'areaname',value:'areacode',checkStrictly: 'true'}" :show-all-levels="false" :options="areatreelist" @change="handleChange"></el-cascader>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item label="店铺地址">
                                <el-input v-model="form.address" style="width: 300px;" placeholder="请输入店铺地址" clearable size="small" type="text" />
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <el-form-item>
                                <el-button type="success" icon="el-icon-search" size="mini" @click="getbusinesslist">搜索</el-button>
                                <el-button type="warning" icon="el-icon-refresh-right" size="mini" @click="resetsearch">重置</el-button>
                                <el-button type="primary" icon="el-icon-plus" size="mini" @click="addbusiness">新增</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table :data="businesslist" style="width: 100%" v-loading="loading" :row-class-name="tableRowClassName">
                    <el-table-column label="序号" align="center" type="index" width="60" fixed="left"></el-table-column>
                    <el-table-column prop="name" label="商家名" align="center"></el-table-column>
                    <el-table-column prop="address" label="商家详细地址" align="center"></el-table-column>
                    <el-table-column prop="mapaddress" label="地图坐标" align="center"></el-table-column>
                    <el-table-column prop="type" label="商家类型" align="center" width="80" :formatter="typeFormatter"></el-table-column>
                    <el-table-column prop="lastUpdateName" label="最后一次修改人" align="center" width="120"></el-table-column>
                    <el-table-column prop="lastUpdateDate" label="最后一次修改时间" align="center" width="160"></el-table-column>
                    <el-table-column prop="status" label="状态" align="center" :formatter="businessFormatter" width="60"> </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center" width="220">
                        <template slot-scope="scope">
                            <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                            <el-button @click="updateStatus(scope.row.id,0)" size="small" v-if="scope.row.status==1" type="text" class="dangertext">禁用</el-button>
                            <el-button @click="updateStatus(scope.row.id,1)" size="small" v-if="scope.row.status==0" type="text" class="succestext">启用</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination v-show="total > 0" :total="total" :page.sync="form.pageNum" :limit.sync="form.pageSize" @pagination="getbusinesslist" />
            </div>
        </div>
        <edit ref="edit"></edit>
    </div>
</template>
<script>
import { areaTree, areaName } from '@/apps/utils/area'
import { levelList } from '@/apps/api/level'
import { businessList, businessUpdate } from '@/apps/api/business'
import edit from '@/apps/views/business/edit'
export default {
    name: 'businesslist',
    components: {
        edit
    },
    data () {
        return {
            loading: false,
            form: {
                type: '',
                name: '',
                areacode: '',
                address: '',
                pageSize: 15,
                pageNum: 1
            },
            total: 0,
            businesslist: [],
            areatreelist: [],
            leveloptions: [],
        }
    },
    created () {
        this.getbusinesslist()
        this.getLevel()
        this.getArea()
    },
    methods: {
        // 获取等级列表参数
        getLevel () {
            let data = {
                pageNum: 0,
            }
            levelList(data).then(res => {
                this.leveloptions = res.data
            })
        },
        // 获取地区列表参数
        getArea () {
            let data = {
                areacode: 620100000000
            }
            areaTree(data).then(res => {
                this.areatreelist = res.data
            })
        },
        // 禁止状态参数变色
        tableRowClassName ({ row, rowIndex }) {
            if (row.status == 0) {
                return 'danger-row';
            }
            return '';
        },

        resetsearch () {
            this.form = {
                type: '',
                name: '',
                areacode: '',
                address: '',
                pageSize: 15,
                pageNum: 1
            }
            this.getbusinesslist()
        },
        handleChange (value) {
            if (value) {
                this.form.areacode = value[value.length - 1]
            }
        },
        // 获取商户
        getbusinesslist () {
            this.loading = true
            businessList(this.form).then(res => {
                if (res.code == 200) {
                    this.businesslist = res.data
                    this.total = res.total
                    this.loading = false
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                    this.loading = false
                }
            })
        },
        // 状态修改
        updateStatus (id, status) {
            this.loading = true
            let data = {
                id: id,
                status: status,
            }
            businessUpdate(data).then(res => {
                if (res.code == 200) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                }
                this.getbusinesslist()
            })
        },
        // 商家等级翻译
        typeFormatter (row, column) {
            for (let i = 0; i < this.leveloptions.length; i++) {
                if (row.type == this.leveloptions[i].id) {
                    return this.leveloptions[i].type
                }
            }
            return row.type
        },
        // 状态翻译
        businessFormatter (row, column) {
            switch (row.status) {
                case '0':
                    return "禁用"
                case '1':
                    return "启用"
            }
        },
        // 打开修改用户页面
        edit (value) {
            setTimeout(() => {
                this.$refs.edit.handleupdate(value)
            }, 1000)
        },
        addbusiness () {
            setTimeout(() => {
                this.$refs.edit.handleadd()
            }, 1000)
        }

    }
}
</script>
<style>
</style>
