import Vue from 'vue'
import VueRouter from 'vue-router'

import index from '@/apps/views/index'
import login from '@/apps/public/login'
import main from '@/apps/public/main'

import user from '@/apps/views/user/index'
import business from '@/apps/views/business/index'
import goods from '@/apps/views/goods/index'
import level from '@/apps/views/level/index'
import xcd from '@/apps/views/xcd/index'
import xcdinfo from '@/apps/views/xcd/info.vue'

Vue.use(VueRouter)



export default new VueRouter({
    mode: 'history',
    routes: [{
        path: '/login',
        name: '系统登陆',
        component: login
    }, {
        path: '/',
        name: 'Home',
        redirect: '/system/index' // 默认页直接重定向到你要显示的默认页
    }, {
        path: '/system/',
        name: 'system',
        component: main,
        children: [{
                path: 'index', // 默认显示页路径
                name: '主页',
                component: index
            },
            {
                path: 'user',
                name: '用户',
                component: user
            },
            {
                path: 'business',
                name: '商家',
                component: business
            },
            {
                path: 'goods',
                name: '商品',
                component: goods
            },
            {
                path: 'level',
                name: '商家等级',
                component: level
            }, {
                path: 'xcd',
                name: '巡查单',
                component: xcd
            },
        ]
    }, {
        path: '/xcdinfo/*',
        name: 'xcdinfo',
        component: xcdinfo // 默认页直接重定向到你要显示的默认页
    }, ]
})