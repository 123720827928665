/* eslint-disable space-before-function-paren */
import request from '@/apps/utils/request'

// 巡查单查询
export function xcdList(data) {
    return request({
        url: '/mn/xcdinfo/list',
        method: 'POST',
        data: data
    })
}

// 巡查单查询
export function xcdAdd(data) {
    return request({
        url: '/mn/xcdinfo/add',
        method: 'POST',
        data: data
    })
}

// 巡查单查询
export function xcdUpdate(data) {
    return request({
        url: 'mn/xcdinfo/update',
        method: 'POST',
        data: data
    })
}

export function xcdExportExcel(data) {
    return request({
        url: 'mn/xcdinfo/exportExcel',
        method: 'GET',
        responseType: "blob",

        params: data
    })
}


export function xcdExportPpt(year, week) {
    return request({
        url: 'mn/ppt?year=' + year + '&week=' + week,
        method: 'GET',
        responseType: "blob",
    })
}

export function xcdById(id) {
    return request({
        url: 'mn/xcdinfo/' + id,
        method: 'GET',
    })
}

export function xcdEmailPpt(data) {
    return request({
        url: 'util/email',
        method: 'GET',
        params: data

    })
}